<template>
    <div class="for-creators">
        <discover-a-new-world></discover-a-new-world>

        <div class="content">
            <what-is-nft></what-is-nft>

            <collaborate></collaborate>

            <every-piece></every-piece>

            <official-contract></official-contract>

            <share-your-works></share-your-works>

            <take-part></take-part>
        </div>

        <creators-form></creators-form>
    </div>
</template>

<script>
import DiscoverANewWorld from './snippets/DiscoverANewWorld.vue'
import WhatIsNft from './snippets/WhatIsNft.vue'
import EveryPiece from './snippets/EveryPiece.vue'
import ShareYourWorks from './snippets/ShareYourWorks.vue'
import Collaborate from './snippets/Collaborate.vue'
import TakePart from './snippets/TakePart.vue'
import OfficialContract from './snippets/OfficialContract.vue'
import CreatorsForm from './snippets/CreatorsForm.vue'

export default {
    name: 'for-creators',
    components: {
        'discover-a-new-world': DiscoverANewWorld,
        'what-is-nft': WhatIsNft,
        'collaborate': Collaborate,
        'every-piece': EveryPiece,
        'official-contract': OfficialContract,
        'share-your-works': ShareYourWorks,
        'take-part': TakePart,
        'creators-form': CreatorsForm
    }
}
</script>

<style lang="less">
.for-creators {
    background-color: var(--dark-blue);
    color: var(--white);
    overflow: hidden;

    > .content {
        width: var(--main-width-full);  
        margin: auto;
    }
}
</style>
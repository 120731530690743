<template>
    <div class="what-is-nft" id="more">
        <div class="title" v-html="$i18n.get(`What is&nbsp <div> NFT? </div>`)"></div>

        <div class="discription">
            {{ $i18n.get(`NFT token (non-fungible token) is a digital certificate for a unique object that is stored in the blockchain. NFT is not a crypto currency in the usual sense. Each NFT is a unique token that has no analogue and guarantees the authenticity of the item providing exclusive rights to own it.`) }}
        </div>

        <div class="images">
            <div class="static">
                <div class="info">
                    <div class="title">
                        {{ $i18n.get(`Static`) }}
                    </div>

                    <div class="discription">
                        {{ $i18n.get(`Constant characteristics: text description and image or video.`) }}
                    </div>
                </div>
            </div>

            <div class="dynamic">
                <div class="info">
                    <div class="title">
                        {{ $i18n.get(`Dynamic`) }}
                    </div>

                    <div class="discription">
                        {{ $i18n.get(`Variable properties: may change depending on what is happening in the metaverse or reality.`) }}
                    </div>
                </div>

                <div class="cards"></div>
            </div>
        </div>

        <div class="title" v-html="$i18n.get(`And what is&nbsp <div> Metaverse? </div>`)"></div>

        <div class="text-and-image">
            <div class="image">
                <img :src="require('../images/metaverse-cards.webp')" alt="">
                <div class="shadow"></div>
            </div>

            <div class="text" v-html="$i18n.get(`NFTs are widely used in the metaverses as the backbone of their economy, whereall major assets are presented in the form of non-fungible tokens.<br><br>Metaverse is a shared collective virtual space, a digital universe where anything we could only imagine can exist. It can be both games and real world simulators.<br><br>The demand for decentralization has fueled the global growth of the entire GameFi concept, where gaming meets financial instruments.`)"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'what-is-nft',
}
</script>

<style lang="less">
    .what-is-nft {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        color: var(--white);

        > .title {
            font-weight: 800;
            font-size: 46px;
            line-height: 69px;
            display: flex;
            margin-top: 65px;

            > div {
                background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        > .discription {
            margin-top: 20px;
            font-size: 20px;
            line-height: 34px;
            max-width: 765px;
        }

        > .images {
            display: flex;
            column-gap: 40px;
            margin-top: 52px;

            > .static, .dynamic {
                width: 570px;
                height: 530px;
                position: relative;
                background-position: center;
                background-size: cover;
                border-radius: 30px;

                > .info {
                    padding: 30px 90px;

                    > .title {
                        font-weight: 800;
                        font-size: 28px;
                        line-height: 42px;
                    }

                    > .discription {
                        margin: auto;
                        margin-top: 3px;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }

            }

            > .static {
                background-image: url(../images/what-is-nft-static.webp);

            }

            > .dynamic {
                background-image: url(../images/what-is-nft-dynamic.webp);

                > .cards {
                    background-image: url(../images/what-is-nft-dynamic-cards.webp);
                    width: 412px;
                    height: 300px;
                    background-position: center;
                    background-size: cover;
                    margin: auto;
                }
            }
        }

        > .text-and-image {
            width: 100%;
            display: flex;
            column-gap: 50px;
            margin-top: 46px;
            position: relative;



            > .image {
                margin-left: 50px;
                width: 595px;
                height: 461px;
                z-index: 1;
                object-fit: cover;
                position: relative;

                > img {
                    width: 100%;
                }

                &::before{
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: -1;
                    background: rgba(166, 145, 235, 0.6);
                    filter: blur(190px);
                }
            }

            > .text {
                width: 485px;
                font-size: 20px;
                line-height: 34px;
                text-align: left;
            }
        }
    }

@media (max-width: 1279px) {
    .what-is-nft {
        > .title {
            font-size: 42px;
        }

        > .discription {
            font-size: 18px;
        }

        > .images {
            > .static, .dynamic {
                width: 413px;
                height: 450px;

                > .info {
                    padding: 30px 60px;
                }
            }

            > .dynamic {
                > .cards {
                    width: 298px;
                    height: 217px;
                }
            }
        }

        > .text-and-image {
            column-gap: 40px;
            margin-top: 50px;

            > .shadow {
                width: 378px;
                height: 253px;
                margin-top: 46px;
                margin-left: 13px;
            }

            > .image {
                margin-left: 0;
                width: 410px;
                height: 317px;
            }

            > .text {
                margin-top: -12px;
                width: 390px;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .what-is-nft {
        > .title {
            line-height: 51px;
            font-size: 34px;
            margin-top: 55px;
        }

        > .discription {
            margin-top: 13px;
            font-size: 16px;
            line-height: 28px;
            max-width: 600px;
        }

        > .images {
            margin-top: 43px;

            > .static, .dynamic {
                width: 324px;
                height: 350px;

                > .info {
                    padding: 15px 30px;

                    > .title {
                        font-size: 24px;
                        line-height: 36px;
                    }

                    > .discription {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }

            > .dynamic {
                > .cards {
                    width: 235px;
                    height: 170px;
                }
            }
        }

        > .text-and-image {
            column-gap: 40px;
            margin-top: 56px;
            justify-content: center;

            > .shadow {
                width: 302px;
                height: 202px;
                margin-top: 15px;
                margin-left: -350px;
                filter: blur(120px);
            }

            > .image {
                width: 295px;
                height: 228px;
            }

            > .text {
                width: 330px;
                font-size: 16px;
                line-height: 28px;
                margin-top: -30px;
            }
        }
    }
}

@media (max-width: 767px) {
    .what-is-nft {
        padding: 0 15px;
        align-items: flex-start;
        text-align: start;

        > .title {
            line-height: 33px;
            font-size: 22px;
            margin-top: 40px;
        }

        > .discription {
            margin-top: 10px;
            font-size: 14px;
            line-height: 24px;
            max-width: 100%;
        }

        > .images {
            width: 100%;
            margin-top: 15px;
            flex-direction: column;
            row-gap: 15px;

            > .static, .dynamic {
                width: 100%;
                height: 250px;
                display: flex;
                flex-direction: column-reverse;

                > .info {
                    padding: 12px 15px;

                    > .title {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    > .discription {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }

            > .static {
                background-image: url(../images/what-is-nft-static-shadow-reverse.webp);
            }

            > .dynamic {
                > .cards {
                    width: 170px;
                    height: 124px;
                }
            }
        }

        > .text-and-image {
            column-gap: 0px;
            row-gap: 20px;
            margin-top: 12px;
            flex-direction: column;
            width: 100%;

            > .shadow {
                width: 302px;
                height: 220px;
                margin: 0;
                margin-top: -290px;
            }

            > .image {
                width: 100%;
                height: auto;

                > img {
                    width: 100%;
                }
            }

            > .text {
                width: 100%;
                font-size: 14px;
                line-height: 24px;
                margin: 0;
            }
        }
    }
}
</style>
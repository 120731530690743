<template>
    <div class="take-part">
        <div class="title" v-html="$i18n.get(`<span>Take part and</span> win competitions`)"></div>

        <div class="description">
            {{ $i18n.get(`Together with famous brands, we hold competitions with massive prize pools to select the best art works. Brands invite the best of the best artists for permanent cooperation.`) }}
        </div>

        <div class="image">
            <img :src="require('../images/take-part-image.webp')" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'take-part',
}
</script>

<style lang="less">
.take-part {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #0D255D 11.79%, rgba(13, 37, 93, 0) 100%);
    border-radius: 30px;
    position: relative;
    margin-top: 100px;

    > .title {
        margin-top: 75px;
        width: 760px;
        font-weight: 800;
        font-size: 46px;
        line-height: 56px;
        background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;

        > span {
            background: var(--white);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }
    }

    > .description {
        width: 950px;
        margin-top: 30px;
        font-size: 20px;
        line-height: 32px;
    }

    > .image {
        width: 878px;
        margin-bottom: 40px;
        margin-top: 30px;
        object-fit: cover;
        position: relative;
        z-index: 1;

        &::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            background: #A691EB;
            opacity: 0.25;
            filter: blur(236.31px);
        }

        img {
            width: 100%
        }
    }
}

@media (max-width: 1279px) {
    .take-part {
        margin-top: 70px;

        .title {
            width: 690px;
            font-size: 42px;
            line-height: 54px;
            margin-top: 60px;
        }

        .description {
            margin-top: 12px;
            width: 725px;
            font-size: 18px;
            line-height: 30px;
        }

        .image {
            width: 700px;
            margin-bottom: 50px;
            margin-top: 20px;
        }
    }
}

@media (max-width: 1023px) {
    .take-part {
        margin-top: 60px;

        .title {
            width: 560px;
            font-size: 34px;
            line-height: 44px;
            margin-top: 50px;
        }

        .description {
            margin-top: 16px;
            width: 580px;
            font-size: 16px;
            line-height: 28px;
        }

        .image {
            width: 548px;
            margin-bottom: 30px;
            margin-top: 25px;
        }
    }
}

@media (max-width: 767px) {
    .take-part {
        margin-top: 50px;
        padding: 0 15px;

        .title {
            width: 100%;
            font-size: 22px;
            line-height: 30px;
            margin-top: 36px;
        }

        .description {
            margin-top: 12px;
            width: 100%;
            font-size: 14px;
            line-height: 24px;
        }

        .image {
            width: 100%;
            margin-bottom: 20px;
            margin-top: 5px;
        }
    }
}
</style>
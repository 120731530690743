<template>
    <div class="discover-a-new-world">
        <div class="container">
            <div class="info">
                <div class="title">
                    {{ $i18n.get(`Discover a new world of NFT and Metaverse`) }}
                </div>

                <div class="description">
                    {{ $i18n.get(`Create and sell your artworks to millions of people around the world`) }}
                </div>

                <div class="buttons" v-if="!$store.state.isMobile">
                    <w-button class="button" @click="redirectToMore" :size="$store.state.isMobile ? 'small' : 'middle'" block="block" type='white-border' >
                        {{ $i18n.get(`Learn more`) }}
                    </w-button>

                    <w-button class="button" @click="redirectToForm" :size="$store.state.isMobile ? 'small' : 'middle'" block="block" type='orange'>
                        {{ $i18n.get(`Join now`) }}
                    </w-button>
                </div>
            </div>

            <div class="island-image">
                <img :src="require('../images/discover-a-new-world-island.webp')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'discover-a-new-world',
    methods: {
        redirectToMore(){
            window.location.href='#more'
        },
        redirectToForm(){
            window.location.href='#form'
        }
    }
}
</script>

<style lang="less">
.discover-a-new-world {
    background-image: url(../images/discover-a-new-world-bg.webp);
    background-position: center;
    background-size: cover;

    > .container {
        height: 610px;
        display: flex;
        flex-direction: row;
        margin: auto;
        width: var(--main-width-full);

        > .info {
            z-index: 1;
            margin-top: 154px;

            > .title {
                background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
                font-weight: 800;
                font-size: 46px;
                line-height: 56px;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                max-width: 554px;
            }

            > .description {
                color: var(--white);
                font-size: 20px;
                line-height: 32px;
                margin-top: 8px;
                max-width: 418px;
            }

            > .buttons {
                margin-top: 48px;
                display: flex;
                column-gap: 10px;
                max-width: 410px;

                > .button {
                    height: 60px;
                    font-size: 16px;

                    &:first-child {
                        border-width: 2px;
                    }
                }
            }
        }

        > .island-image {
            margin-top: 36px;
            width: 824px;
            height: 520px;
            margin-left: -145px;
            margin-right: -145px;
            object-fit: cover;

            > img {
                width: 100%;
            }

        }
    }
}

@media (max-width: 1279px) {
    .discover-a-new-world{
        > .container {
            height: 530px;
            width: var(--main-width-full);

            > .info {
                margin-top: 110px;

                > .title {
                    font-size: 42px;
                    max-width: 478px;
                }

                > .description {
                    color: var(--white);
                    font-size: 20px;
                    line-height: 32px;
                    max-width: 418px;
                }
            }

            > .island-image {
                margin-top: 92px;
                width: 587px;
                height: 370px;
                margin-left: -60px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .discover-a-new-world{
        .container {
            height: 440px;
            width: var(--main-width-full);

            > .info {
                margin-top: 85px;

                > .title {
                    font-size: 34px;
                    line-height: 44px;
                    max-width: 379px;
                }

                > .description {
                    font-size: 18px;
                    line-height: 28px;
                    margin-top: 10px;
                    max-width: 365px;
                }

                > .buttons {
                    margin-top: 36px;
                    max-width: 326px;

                    > .button {
                        height: 50px;
                        font-size: 14px;
                    }
                }
            }

            > .island-image {
                margin-top: 63px;
                width: 491px;
                height: 310px;
                margin-left: -85px;
            }
        }
    }
}

@media (max-width: 767px) {
    .discover-a-new-world {
        background-position: -480px;

        .container {
            width: 100%;
            flex-direction: column;
            height: auto;
            padding: 34px 15px 45px;
            align-items: center;

            > .info {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-top: 0;
                padding: 0 15px;

                > .title {
                    font-size: 23px;
                    line-height: 28px;
                    max-width: 100%;
                }

                > .description {
                    font-size: 14px;
                    line-height: 21px;
                    max-width: 100%;
                }
            }

            > .island-image {
                margin: -25px -30px 0;
                width: calc(100% + 60px);
                height: auto;
                margin: 0 -15px 0;

                > img {
                    width: 100%;
                    height: auto;
                }
            }

            > .buttons {
                display: flex;
                width: 100%;
                column-gap: 10px;
                max-width: 450px;
                margin: auto;

                > .button {
                    &:first-child {
                        border-width: 2px;
                    }
                }
            }
        }
    }
}
</style>
<template>
    <div class="colaborate">
        <div class="title" v-html="$i18n.get(`<span>Collaborate with</span> tier-1 brands and game developers`)"></div>

        <div class="description">
            {{ $i18n.get(`TRUE Ecosystem brings everyone under one roof to create cool NFT and GameFi projects. Artists get the opportunity to work with global brands or participate in the creation of new game worlds.`) }}
        </div>

        <div class="image">
            <img v-if="!$store.state.isMobile" :src="require('../images/collaborate-image.webp')" alt="">

            <img v-else :src="require('../images/collaborate-image-mobile.webp')" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'colaborate',
}
</script>

<style lang="less">
.colaborate {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0D255D;
    border-radius: 30px;
    position: relative;
    margin-top: 100px;

    &::before {
        content: '';
        position: absolute;
        background-image: url(../images/collaborate-left.webp);
        background-position: center;
        background-size: cover;
        width: 490px;
        height: 413px;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 30px;
    }

    &::after {
        content: '';
        position: absolute;
        background-image: url(../images/collaborate-right.webp);
        background-position: center;
        background-size: cover;
        width: 410px;
        height: 472px;
        top: 0;
        right: 0;
        border-top-right-radius: 30px;
    }

    > .title {
        margin-top: 75px;
        width: 740px;
        font-weight: 800;
        font-size: 46px;
        line-height: 56px;
        background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;

        > span {
            background: var(--white);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }
    }

    > .description {
        width: 900px;
        margin-top: 30px;
        font-size: 20px;
        line-height: 32px;
    }

    > .image {
        width: 668px;
        margin-bottom: 90px;
        margin-top: -165px;
        object-fit: cover;
        z-index: 1;

        img {
            width: 100%
        }
    }
}

@media (max-width: 1279px) {
    .colaborate {
        margin-top: 70px;

        &::before {
            width: 351px;
            height: 296px;
        }

        &::after {
            width: 293px;
            height: 338px;
        }

        .title {
            width: 668px;
            font-size: 42px;
            line-height: 54px;
            margin-top: 60px;
        }

        .description {
            margin-top: 12px;
            width: 724px;
            font-size: 18px;
            line-height: 30px;
        }

        .image {
            width: 596px;
            margin-bottom: 70px;
            margin-top: -150px;
        }
    }
}

@media (max-width: 1023px) {
    .colaborate {
        margin-top: 60px;

        &::before {
            width: 278px;
            height: 234px;
        }

        &::after {
            width: 233px;
            height: 268px;
        }

        .title {
            width: 569px;
            font-size: 34px;
            line-height: 44px;
            margin-top: 50px;
        }

        .description {
            margin-top: 16px;
            width: 578px;
            font-size: 16px;
            line-height: 28px;
        }

        .image {
            width: 479px;
            margin-bottom: 70px;
            margin-top: -125px;
        }
    }
}

@media (max-width: 767px) {
    .colaborate {
        margin-top: 50px;
        padding: 0 15px 0;

        &::before {
            width: 145px;
            height: 122px;
        }

        &::after {
            width: 150px;
            height: 173px;
        }

        .title {
            width: 100%;
            font-size: 22px;
            line-height: 30px;
            margin-top: 36px;
            padding: 0 15px;
        }

        .description {
            margin-top: 12px;
            width: 100%;
            font-size: 14px;
            line-height: 24px;
            padding: 0 15px;
        }

        .image {
            width: 100%;
            margin-bottom: 43px;
            margin-top: -35%;        }
    }
}
</style>
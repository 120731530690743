<template>
    <div class="share-your-works">
        <div class="text">
            <div class="title" v-if="!$store.state.isMobile" v-html="$i18n.get(` <span> Share your works </span> <br> and achievements`)"></div>

            <div class="title" v-else v-html="$i18n.get(` <span> Share your works </span> and achievements`)"></div>

            <div class="image" v-if="$store.state.isMobile">
                <img :src="require('../images/share-your-works.webp')" alt="">
            </div>

            <div class="description">
                {{ $i18n.get(`Create your public artist profile on the marketplace showcasing your artworks to show everyone what brands you collaborated with, what games you participated in and also what competitions you won.`) }}
            </div>
        </div>

        <div class="image" v-if="!$store.state.isMobile">
            <img :src="require('../images/share-your-works.webp')" alt="">
        </div>
    </div>
</template>

<script>

export default {
    name: 'share-your-works',
}
</script>

<style lang="less">
.share-your-works {
    display: flex;
    flex-direction: row;
    align-items: left;

    > .text {
        margin-top: 147px;
        margin-right: 135px;
        width: 500px;

        > .title {
            font-weight: 800;
            font-size: 46px;
            line-height: 56px;

            > span {
                background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        > .description {
            margin-top: 30px;
            font-size: 20px;
            line-height: 34px;
        }
    }

    > .image {
        margin-top: 70px;
        width: 500px;
        object-fit: cover;
        position: relative;
        z-index: 1;

        &::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            background: #3D61B5;
            opacity: 0.4;
            filter: blur(142.952px);
        }

        > img {
            width: 100%;
        }
    }
}

@media (max-width: 1279px) {
    .share-your-works {
        > .text {
            margin-top: 90px;
            width: 425px;
            margin-right: 80px;

            > .title {
                font-weight: 800;
                font-size: 42px;
                line-height: 54px;
            }

            > .description {
                margin-top: 20px;
                font-size: 18px;
                line-height: 30px;
            }
        }

        > .image {
            width: 388px;
        }
    }
}

@media (max-width: 1023px) {
    .share-your-works {
        > .text {
            margin-top: 70px;
            margin-right: 60px;
            width: 335px;

            > .title {
                font-weight: 800;
                font-size: 34px;
                line-height: 44px;
            }

            > .description {
                margin-top: 20px;
                font-size: 16px;
                line-height: 28px;
            }
        }

        > .image {
            margin-top: 40px;
            width: 311px;
        }
    }
}

@media (max-width: 767px) {
    .share-your-works {
        flex-direction: column;
        padding: 0 15px;

        > .text {
            width: 100%;
            object-fit: cover;
            margin: 50px 0 0;

            > .title {
                width: 100%;
                font-weight: 800;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
            }

            > .image {
                width: 100%;
                margin: 8px 0 0;

                > img {
                    width: 100%;
                }
            }

            > .description {
                margin: 3px 0 0;
                font-size: 14px;
                line-height: 24px;
                width: 100%;
            }
        }
    }
}
</style>
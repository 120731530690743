<template>
    <div class="ecosystem-block">
        <div class="container">
            <h2 class="title">
                {{ $i18n.get(`TRUE Ecosystem`) }}
            </h2>

            <div class="blocks">
                <div class="column" v-for="(column, index) of filteredItems" :key="index">
                    <div class="item" v-for="(item, index) of column" :key="index + 0.1">
                        <div class="title" :class="titleClass(item.title)">
                            {{ item.title }}
                        </div>

                        <div class="description">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ecosystem-block',
    data() {
        return {
            items: {
                people: {
                    title: this.$i18n.get(`True Community`),
                    description: this.$i18n.get(`A community of like-minded people and token holders who share the TRUE values and enjoy interacting with the game world the way they want and anytime they want. In just a click.`)
                },
                metaverse: {
                    title: this.$i18n.get(`True Metaverse`),
                    description: this.$i18n.get(`A GameFi ecosystem for gamers, developers and brands. Gamers are invited to a variety of game worlds combined all in one place. Developers are set free from building their own infrastructure to focus solely on game content. Brands can quickly mint and sell their own NFTs, which can be used by players in game worlds and collectors inside or outside the ecosystem.`)
                },
                earn: {
                    title: this.$i18n.get(`True Earn`),
                    description: this.$i18n.get(`A The Loyalty Program to multiply your crypto capital through the attractive Play-to-Earn activities and classic DeFi tools. The place where your crypto assets work for you.`)
                },
                marketplace: {
                    title: this.$i18n.get(`True Marketplace`),
                    description: this.$i18n.get(`NFT Marketplace is an integral part of the ecosystem. This is a repository of NFT entities of all metaverse projects. Flexibility and scalability of the marketplace allows any projects in any of the blockchains to use it.`)
                },
                app: {
                    title: this.$i18n.get(`True App`),
                    description: this.$i18n.get(`The heart of the ecosystem allowing users to access our partners projects. With the use of seamless technologies gamers don't have to concern about wallets, fees, transfers and blockchains. Access your game assets easily via mobile interface with the appropriate security and privacy.`)
                },
            }
        }
    },
    computed: {
        filteredItems() {
            if (this.$store.state.isMobile) {
                return [ this.items ]
            } else if (this.$store.state.isTablet) {
                return [
                    [
                        this.items.people, this.items.marketplace, this.items.earn
                    ],
                    [
                        this.items.metaverse, this.items.app
                    ]
                ]
            } else {
                return [
                    [
                        this.items.people, this.items.marketplace
                    ],
                    [
                        this.items.earn, this.items.app
                    ],
                    [
                        this.items.metaverse,
                    ],
                ]
            }
        }
    },
    methods: {
        titleClass(title) {
            return {
                '-people': title === 'True Community',
                '-metaverse': title === 'True Metaverse',
                '-marketplace': title === 'True Marketplace',
                '-trueapp': title === 'True App',
                '-earn': title === 'True Earn',
            }
        }
    }
}
</script>

<style lang="less">
.ecosystem-block {
    background-color: var(--dark-blue);
    background-image: url(../images/ecosystem-bg.webp);
    background-size: 2560px 100%;
    background-position: center;
    min-height: 870px;
    color: var(--white);
    padding: 90px 15px;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .title {
            font-weight: 800;
            font-size: 36px;
            line-height: 144%;
        }

        > .blocks {
            margin-top: 30px;
            display: flex;
            column-gap: 20px;
            align-items: flex-start;

            > .column {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                width: calc(100% / 3);

                > .item {
                    padding: 35px;
                    background: #193165;
                    border-radius: 30px;
                    flex-grow: 1;

                    > .title {
                        font-weight: 800;
                        font-size: 22px;
                        line-height: 26px;

                        &.-people {
                            color: #56E8F1
                        }

                        &.-metaverse {
                            color: #5F8CFF;
                        }

                        &.-marketplace {
                            color: #F06BE3;
                        }

                        &.-profile {
                            color: #FFA176;
                        }

                        &.-earn {
                            color: #FFD159;
                        }

                        &.-trueapp {
                            background: linear-gradient(88.01deg, #DE669F -32.92%, #CB34E3 151.39%);
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }

                    > .description {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 25px;
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .ecosystem-block {
        padding: 40px 15px;

        > .container {

            > .title {
                font-size: 36px;
                line-height: 144%;
            }

            > .blocks {
                margin-top: 30px;
                display: flex;
                column-gap: 20px;

                > .column {
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    width: calc(100% / 2);

                    > .item {
                        padding: 35px;
                        background: #193165;
                        border-radius: 30px;
                        flex-grow: 1;

                        > .title {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .ecosystem-block {
        padding: 15px 15px;
        background-size: 1886px 100%;
        background-position: -964px center;

        > .container {

            > .title {
                font-size: 24px;
                line-height: 144%;
            }

            > .blocks {
                margin-top: 30px;
                display: flex;
                column-gap: 20px;

                > .column {
                    row-gap: 10px;
                    width: 100%;

                    > .item {
                        padding: 15px;
                        background: #193165;
                        border-radius: 20px;
                        flex-grow: 1;

                        > .title {
                            font-size: 18px;
                            line-height: 22px;
                        }

                        > .description {
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>

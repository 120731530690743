<template>
    <div class="are-you-ready" id="form">
        <div class="content">
            <div class="title" v-html="$i18n.get(` <span>Are you ready for</span> TRUE creating?`)"></div>

            <div class="description">
                {{ $i18n.get(`Give us as detailed info as possible to help your application stand out!`) }}
            </div>

            <div class="form">
                <w-input v-model="fields.name.value"
                    @blur="blurField('name')"
                    :is-not-valid="!fields.name.valid && fields.name.touch || Boolean(fields.name.error.length)">

                    <template #label>
                        {{ $i18n.get(`Full name / artist name `) }}
                    </template>

                    <template #not-valid>
                        <template>
                            {{ $i18n.get(`Name not valid`) }}
                        </template>
                    </template>
                </w-input>

                <div class="-flex">
                    <w-input class="field" v-model="fields.email.value"
                        @blur="blurField('email')"
                        :is-not-valid="!fields.email.valid && fields.email.touch || Boolean(fields.email.error.length)">

                        <template #label>
                            {{ $i18n.get(`Email`) }}
                        </template>

                        <template #not-valid>
                            <template>
                                {{ $i18n.get(`Email not valid`) }}
                            </template>
                        </template>
                    </w-input>

                    <w-select v-model="fields.experience.value"
                        @blur="blurField('subject')"
                        :list="subjects"
                        :is-not-valid="!fields.experience.valid && fields.experience.touch">

                        <template #label>
                            {{ $i18n.get(`How long have you been an artist for?`) }}
                        </template>

                        <template #not-valid>
                            {{ $i18n.get(`Field is empty`) }}
                        </template>
                    </w-select>

                    <w-input class="field" v-model="fields.portfolio.value"
                        @blur="blurField('portfolio')"
                        :is-not-valid="!fields.portfolio.valid && fields.portfolio.touch || Boolean(fields.portfolio.error.length)">

                        <template #label>
                            {{ $i18n.get(`Links to your portfolio`) }}
                        </template>

                        <template #not-valid>
                            <template>
                                {{ $i18n.get(`Links not valid`) }}
                            </template>
                        </template>
                    </w-input>

                    <w-input class="field" v-model="fields.instagram.value"
                        @blur="blurField('instagram')"
                        :is-not-valid="!fields.instagram.valid && fields.instagram.touch || Boolean(fields.instagram.error.length)">

                        <template #label>
                            {{ $i18n.get(`Instagram`) }}
                        </template>

                        <template #not-valid>
                            <template>
                                {{ $i18n.get(`Instagram not valid`) }}
                            </template>
                        </template>
                    </w-input>

                    <w-input class="field" v-model="fields.twitter.value"
                        @blur="blurField('twitter')"
                        :is-not-valid="!fields.twitter.valid && fields.twitter.touch || Boolean(fields.twitter.error.length)">

                        <template #label>
                            {{ $i18n.get(`Twitter`) }} <span class="opacity">{{ $i18n.get(`(optional)`) }}</span>
                        </template>

                        <template #not-valid>
                            <template>
                                {{ $i18n.get(`Twitter not valid`) }}
                            </template>
                        </template>
                    </w-input>

                    <w-input class="field" v-model="fields.NFTLinks.value"
                        @blur="blurField('otherLinks')"
                        :is-not-valid="!fields.NFTLinks.valid && fields.NFTLinks.touch || Boolean(fields.NFTLinks.error.length)">

                        <template #label>
                            {{ $i18n.get(`Links to NFTs on other platforms`) }} <span class="opacity">{{ $i18n.get(`(optional)`) }}</span>
                        </template>

                        <template #not-valid>
                            <template>
                                {{ $i18n.get(`Links not valid`) }}
                            </template>
                        </template>
                    </w-input>
                </div>

                <w-textarea :placeholder="$i18n.get(`Share a little bit more about themselves and their goals`)"
                    v-model="fields.introduce.value"
                    @blur="blurField('introduce')"
                    :is-not-valid="!fields.introduce.valid && fields.introduce.touch || Boolean(fields.introduce.error.length)">

                    <template #label>
                        {{ $i18n.get(`Introduce yourself as an artist`) }}
                    </template>

                    <template #not-valid>
                        <template>
                            {{ $i18n.get(`Text not valid`) }}
                        </template>
                    </template>
                </w-textarea>

                <w-checkbox v-model="isAccepted">
                    <span
                        v-html='$i18n.get(`By accepting this I agree with <a href="/terms-of-use" target="_blank">Тerms and Сonditions</a> and have read and acknowledged <a href="/privacy-policy" target="_blank">Privacy Policy</a>`)'>
                    </span>
                </w-checkbox>

                <w-button @click="send()" :disabled="!isValidFields || !isComplitedFields || hasSaveRequest || !isAccepted" type="blue">
                    <template v-if="hasSaveRequest">
                        {{ $i18n.get(`Sending...`) }}

                        <w-loader type="ring" color="white"></w-loader>
                    </template>

                    <template v-else>
                        {{ $i18n.get(`Send`) }}
                    </template>
                </w-button>
            </div>

            <div class="info">
                {{ $i18n.get(`All product and company names are trademarks™ or registered® trademarks of their respective holders. Use of them does not imply any affiliation with or endorsement by them.`) }}
            </div>
        </div>
    </div>
</template>

<script>
import Mixins from '@/plugins/Mixins'
const Form = Mixins.Form

export default {
    name: 'creators-form',
    mixins: [Form],
    data () {
        return {
            hasSaveRequest: false,
            subjects: [
                'I am just getting started',
                '1-3 years',
                '4-7 years',
                '8-10 years',
                '10 years +'
            ],
            isAccepted: false
        }
    },
    methods: {
        send() {
            this.blurAllField(this.isValidFields)

            if (!this.isValidFields || !this.isAccepted) {
                return
            }

            this.hasSaveRequest = true

            this.$request.post('/creator-send', this.getFields(), () => {
                this.hasSaveRequest = false

                this.$modal.show(this.$options.components['WStatusModal'], {
                    status: true,
                    title: 'Your message has been sent',
                    description: 'Thank you! We will come back with the feedback shortly',
                    button: 'OK, thanks!'
                },
                {
                    name: 'w-status-modal',
                    width: 450
                })

                this.resetFields();
            }, response => {
                this.$modal.show(this.$options.components['WStatusModal'], {
                    status: false,
                    title: 'Something wrong',
                    description: this.$i18n.get(`Please try again or contact <a href="{link}" target="_blank">Support Team</a>.`, { link: this.$store.state.links.telegramCommunity }),
                    button: 'Try again'
                }, {
                    name: 'w-status-modal',
                    width: 450
                })
            })
        }
    },
    created() {
        this.createFields({
            name: {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 2, max: 255}
                }]
            },
            email: {
                required: true,
                rules: [{
                    validator: 'isEmail',
                    options: {}
                }]
            },
            experience: {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 1, max: 255}
                }]
            },
            twitter: {
                rules: [{
                    validator: 'isLength',
                    options: {min: 2, max: 255}
                }]
            },
            instagram: {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 2, max: 255}
                }]
            },
            portfolio: {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 2, max: 255}
                }]
            },
            NFTLinks: {
                rules: [{
                    validator: 'isLength',
                    options: {min: 2, max: 255}
                }]
            },
            introduce: {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 5, max: 5000}
                }]
            },
            pageUrl: {
                rules: [{
                    validator: 'isLength',
                    options: {min: 5}
                }]
            }
        })
        this.setFields({
            pageUrl: window.location.href,
        })
    }
}
</script>

<style lang="less">
.are-you-ready {
    background-size: cover;
    background-position: center;
    background-image: url(../images/are-you-ready-bg.webp);

    > .content {
        width: var(--main-width-full);
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .title {
            width: 775px;
            font-weight: 800;
            font-size: 46px;
            line-height: 56px;
            background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            > span {
                background:var(--white);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        > .description {
            margin-top: 16px;
            width: 720px;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
        }

        .form {
            color: var(--dark-blue);
            margin-top: 50px;
            width: 800px;
            background: var(--white);
            border-radius: 30px;
            position: relative;
            padding: 50px;
            text-align: left;
            row-gap: 20px;
            display: flex;
            flex-direction: column;

            &::before {
                content: '';
                position: absolute;
                background-size: cover;
                background-position: center;
                background-image: url(../images/are-you-ready-zebro.webp);
                width: 250px;
                height: 570px;
                left: 100%;
                top: -54px
            }

            > .-flex {
                display: flex;
                column-gap: 20px;
                row-gap: 20px;
                flex-wrap: wrap;

                > .drop-down-list {
                    flex-grow: 1;
                }

                >.field {
                    width: calc(50% - 10px)
                }
            }

            .opacity {
                opacity: 0.3;
            }

            .btn {
                align-self: flex-start;
            }

            textarea::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--dark-blue-40);
            }
        }

        > .info {
            font-size: 11px;
            font-weight: 400;
            line-height: 20px;
            color: var(--white-40);
            padding: 10px 0 7px 0;
            border-bottom: 1px solid var(--white-10);
            width: 100%;
        }
    }
}

@media (max-width: 1279px) {
    .are-you-ready {
        > .content {
            width: var(--main-width-full);

            > .title {
                width: 710px;
                font-size: 42px;
                line-height: 54px;
            }

            > .description {
                width: 650px;
                font-size: 18px;
            }

            > .form {
                &::before {
                    content: none;
                }
            }

            > .info {
                line-height: 15px;
                text-align: left;
            }
        }
    }
}

@media (max-width: 1023px) {
    .are-you-ready {
        > .content {
            width: var(--main-width-full);

            > .title {
                width: 575px;
                font-size: 34px;
                line-height: 44px;
            }

            > .description {
                width: 574px;
                font-size: 18px;
            }

            > .form {
                margin-top: 30px;
                width: var(--main-width-full);

                .form-group, .drop-down-list {
                    label {
                        font-size: 13px;
                    }
                }

                textarea::placeholder {
                    font-size: 13px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .are-you-ready {
        > .content {
            width: 100%;

            > .title {
                width: 100%;
                font-size: 22px;
                line-height: 30px;
                padding: 0 15px;
            }

            > .description {
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                padding: 0 15px;
            }

            > .form {
                width: 100%;
                padding: 15px 15px 30px;
                border-radius: 20px;

                > .-flex {
                    flex-direction: column;

                    >.field {
                        width: 100%
                    }
                }

                .btn {
                    align-self: auto;
                }

                textarea::placeholder {
                    font-size: 12px;
                }
            }

            > .info {
                font-size: 10px;
                line-height: 14px;
                text-align: center;
                padding: 10px 15px 7px;
            }
        }
    }
}
</style>

<template>
    <div class="every-piece">
        <div class="text">
            <div class="title" v-html="$i18n.get(`Earn from <span> every piece </span> of your art`)"></div>

            <div class="image" v-if="$store.state.isMobile">
                <img :src="require('../images/every-piece-picture.webp')" alt="">
            </div>

            <div class="description">
                {{ $i18n.get(`NFTs allow you to receive a slice of the sales. Every time your work is purchased, you receive royalties. Depending on terms of the partnership, you will be able to receive royalties on the first sale, or on all secondary sales as well.`) }}
            </div>
        </div>

        <div class="image" v-if="!$store.state.isMobile">
            <img :src="require('../images/every-piece-picture.webp')" alt="">
        </div>
    </div>
</template>

<script>

export default {
    name: 'every-piece',
}
</script>

<style lang="less">
.every-piece {
    padding: 100px 0 0;
    display: flex;
    flex-direction: row;
    align-items: left;

    > .text {
        > .title {
            width: 530px;
            font-weight: 800;
            font-size: 46px;
            line-height: 56px;

            > span {
                background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        > .description {
            width: 490px;
            margin-top: 30px;
            font-size: 20px;
            line-height: 34px;
        }
    }

    > .image {
        width: 615px;
        object-fit: cover;
        position: relative;
        z-index: 1;

        &::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            background: #334C85;
            filter: blur(108.198px);;
        }

        > img {
            width: 100%;
        }
    }
}

@media (max-width: 1279px) {
    .every-piece {
        padding-top: 70px;

        > .text {
            > .title {
                width: 486px;
                font-weight: 800;
                font-size: 42px;
                line-height: 54px;
            }

            > .description {
                margin-top: 20px;
                font-size: 18px;
                line-height: 30px;
                width: 380px;
            }
        }

        > .image {
            width: 436px;
            margin-left: -80px;
        }
    }
}

@media (max-width: 1023px) {
    .every-piece {
        padding-top: 60px;

        > .text {

            > .title {
                width: 400px;
                font-weight: 800;
                font-size: 34px;
                line-height: 44px;
            }

            > .description {
                margin-top: 20px;
                font-size: 16px;
                line-height: 28px;
                width: 330px;
            }
        }

        > .image {
            margin-top: 45px;
            width: 332px;
            margin-left: -65px;
        }
    }
}

@media (max-width: 767px) {
    .every-piece {
        padding: 50px 15px 50px;
        flex-direction: column;

        > .text {
            width: 100%;
            object-fit: cover;

            > .title {
                width: 100%;
                font-weight: 800;
                font-size: 22px;
                line-height: 30px;
                padding: 0 15px;
            }

            > .image {
                width: 100%;
                margin: 12px 0 0;
                transform: scale(-1, 1);

                > img {
                    width: 100%;
                }
            }

            > .description {
                margin: 9px -5px 0;
                font-size: 14px;
                line-height: 24px;
                width: 100%;
                padding: 0 15px;
            }
        }
    }
}
</style>
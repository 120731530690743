<template>
    <div class="for-developers-form" ref="join">
        <div class="container">
            <h3 class="title">
                {{ $i18n.get(`Join us`) }}
            </h3>

            <div class="sub-title">
                {{ $i18n.get(`We invite the best projects to join TRUE Metaverse`) }}
            </div>

            <div class="form-group-join-us">
                <div class="form">
                    <w-input v-model="fields.name.value" @blur="blurField('name')"
                        :is-not-valid="!fields.name.valid && fields.name.touch || Boolean(fields.name.error.length)">

                        <template #label>
                            {{ $i18n.get(`Full name`) }}
                        </template>

                        <template #not-valid>
                            <template v-if="fields.name.error.length">
                                {{ fields.name.error }}
                            </template>

                            <template v-else>
                                {{ $i18n.get(`Name not valid`) }}
                            </template>
                        </template>
                    </w-input>

                    <div class="line">
                        <w-input v-model="fields.project.value" @blur="blurField('project')"
                            :is-not-valid="!fields.project.valid && fields.project.touch || Boolean(fields.project.error.length)">

                            <template #label>
                                {{ $i18n.get(`Project's name`) }}
                            </template>

                            <template #not-valid>
                                <template v-if="fields.project.error.length">
                                    {{ fields.project.error }}
                                </template>

                                <template v-else>
                                    {{ $i18n.get(`Project not valid`) }}
                                </template>
                            </template>
                        </w-input>
                        
                        <w-input v-model="fields.website.value" @blur="blurField('website')"
                            :is-not-valid="!fields.website.valid && fields.website.touch || Boolean(fields.website.error.length)">

                            <template #label>
                                {{ $i18n.get(`Website`) }}
                            </template>

                            <template #not-valid>
                                <template v-if="fields.website.error.length">
                                    {{ fields.website.error }}
                                </template>

                                <template v-else>
                                    {{ $i18n.get(`Website not valid`) }}
                                </template>
                            </template>
                        </w-input>
                    </div>

                    <div class="line">
                        <w-input v-model="fields.email.value" @blur="blurField('email')"
                            :is-not-valid="!fields.email.valid && fields.email.touch || Boolean(fields.email.error.length)">

                            <template #label>
                                {{ $i18n.get(`Email`) }}
                            </template>

                            <template #not-valid>
                                <template v-if="fields.email.error.length">
                                    {{ fields.email.error }}
                                </template>

                                <template v-else>
                                    {{ $i18n.get(`Email not valid`) }}
                                </template>
                            </template>
                        </w-input>

                        <w-input v-model="fields.telegram.value" @blur="blurField('telegram')"
                            :is-not-valid="!fields.telegram.valid && fields.telegram.touch || Boolean(fields.telegram.error.length)">

                            <template #label>
                                {{ $i18n.get(`Telegram `) }} <span class="opacity">{{ $i18n.get(`(optional)`) }}</span>
                            </template>

                            <template #not-valid>
                                <template v-if="fields.telegram.error.length">
                                    {{ fields.telegram.error }}
                                </template>

                                <template v-else>
                                    {{ $i18n.get(`Telegram not valid`) }}
                                </template>
                            </template>
                        </w-input>
                    </div>

                    <w-textarea v-model="fields.comment.value" @blur="blurField('question')"
                        :is-not-valid="!fields.comment.valid && fields.comment.touch || Boolean(fields.comment.error.length)">

                        <template #label>
                            {{ $i18n.get(`Comment`) }}
                        </template>

                        <template #not-valid>
                            <template v-if="fields.comment.error.length">
                                {{ fields.comment.error }}
                            </template>

                            <template v-else-if="!fields.comment.value.length">
                                {{ $i18n.get(`Text not valid`) }}
                            </template>

                            <template v-else-if="fields.comment.value.length > 5000">
                                {{ $i18n.get(`The text cannot be more than 5000 characters`) }}
                            </template>
                        </template>
                    </w-textarea>

                    <w-checkbox v-model="isAccepted">
                        <span
                            v-html='$i18n.get(`By accepting this I agree with <a href="/terms-of-use" target="_blank">Тerms and Сonditions</a> and have read and acknowledged <a href="/privacy-policy" target="_blank">Privacy Policy</a>`)'>
                        </span>
                    </w-checkbox>

                    <w-button type="blue" @click="send()" :disabled="!isValidFields || !isComplitedFields || hasSaveRequest || !isAccepted">
                        <template v-if="hasSaveRequest">
                            {{ $i18n.get(`Sending...`) }}

                            <w-loader type="ring" color="white"></w-loader>
                        </template>
                        
                        <template v-else>
                            {{ $i18n.get(`Send`) }}
                        </template>
                    </w-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Mixins from '@/plugins/Mixins'
const Form = Mixins.Form

export default {
    name: 'join-us-form',
    mixins: [Form],
    data() {
        return {
            isAccepted: false,
            hasSaveRequest: false,
        }
    },
    methods: {
        send() {
            this.blurAllField()

            if (!this.isValidFields || !this.isAccepted) {
                return
            }

            this.hasSaveRequest = true

            this.$request.post('/metaverse/join', {...this.getFields(), confirmation: this.isAccepted}, () => {
                this.hasSaveRequest = false

                this.$modal.show(this.$options.components['WStatusModal'], {
                    status: true,
                    title: 'Your message has been sent',
                    description: 'Thank you! We will come back with the feedback shortly',
                    button: 'OK, thanks!'
                },
                {
                    name: 'w-status-modal',
                    width: 450
                })

                this.resetFields();
            }, response => {
                this.setFieldsErrors(response.errors)
                this.hasSaveRequest = false
            })
        },
        formPosition() {
            if (this.$refs.join) {
                this.$emit('change-form-position', this.$refs.join.getBoundingClientRect())
            }
        }
    },
    created() {
         this.createFields({
            'name': {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 2, max: 255}
                }]
            },
            project: {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 2, max: 255}
                }]
            },
            website: {
                rules: [{
                    validator: 'isURL',
                    options: {}
                }]
            },
            email: {
                required: true,
                rules: [{
                    validator: 'isEmail',
                    options: {}
                }]
            },
            telegram: {
                rules: [{
                    validator: 'isLength',
                    options: {min: 0, max: 255}
                }]
            },
            comment: {
                required: true,
                rules: [{
                    validator: 'isLength',
                    options: {min: 5, max: 5000}
                }]
            },
             pageUrl: {
                 rules: [{
                     validator: 'isLength',
                     options: {min: 5}
                 }]
             }
        })
        this.setFields({
            pageUrl: window.location.href,
        })
    },
    mounted() {
        this.formPosition()
        document.addEventListener('scroll', this.formPosition)
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this.formPosition)
    }
}
</script>

<style lang="less">
.for-developers-form {
    background-color: var(--main-grey);
    padding: 60px 0px 80px;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .title {
            font-weight: 700;
            font-size: 36px;
            line-height: 54px;
            text-align: center;
        }

        > .sub-title {
            margin-top: 5px;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
        }

        > .form-group-join-us {
            position: relative;
            max-width: 800px;
            margin: 40px auto 0px;
            z-index: 1;
            
            > .form {
                padding: 50px;
                background-color: var(--white);
                border-radius: 30px;
                display: flex;
                flex-direction: column;
                row-gap: 20px;

                > .line {
                    display: flex;
                    justify-content: space-between;
                    column-gap: 20px;
                    
                    > .form-group {
                        width: calc(50% - 10px);
                    }
                }

                > .btn {
                    align-self: flex-start;
                }

                .opacity {
                    opacity: 0.3;
                }
            }

            &::before {
                content: '';
                z-index: -1;
                position: absolute;
                width: 316px;
                height: 552px;
                right: -173px;
                top: -94px;
                background-image: url(../images/z-bro.webp);
                background-size: 100%;
            }
        }
    }
}

@media (max-width: 1279px) {
    .for-developers-form {
        padding: 50px 0px 70px;

        > .container {
            > .form-group-join-us {
                &::before {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .for-developers-form {
        padding: 30px 0px 50px;

        > .container {
            > .title {
                font-size: 28px;
                line-height: 42px;
            }

            > .sub-title {
                font-size: 14px;
            }
            
            > .form-group-join-us {
                margin-top: 15px;
            }
        }
    }
}

@media (max-width: 767px) {
    .for-developers-form {
        background-color: var(--white);
        padding: 15px 15px 30px 15px;

        > .container {
            > .title {
                font-size: 24px;
                line-height: 36px;
                text-align: left;
            }

            > .sub-title {
                font-size: 12px;
                line-height: 18px;
                text-align: left;
            }
            
            > .form-group-join-us {
                margin-top: 15px;

                > .form {
                    padding: 0px;
                    row-gap: 15px;

                    > .line {
                        flex-direction: column;
                        row-gap: 15px;

                        > .form-group {
                            width: 100%;
                        }
                    }

                    > .btn {
                        align-self: stretch;
                    }
                }
            }
        }
    }
}
</style>

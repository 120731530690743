<template>
    <div class="official-contract">
        <div class="title" v-html="$i18n.get(`Official contract and fiat support`)"></div>

        <div class="description">
            {{ $i18n.get(`We conclude an official agreement with brands and developers, make payments in fiat currency, and also act as a guarantor for all participants of the ecosystem. Bring your clients to us in case it is important for them to comply with all legal subtleties.`) }}
        </div>

        <div class="image">
            <img v-if="!$store.state.isMobile" :src="require('../images/official-contract-image.webp')" alt="">

            <img v-else :src="require('../images/official-contract-image-mobile.webp')" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'official-contract',
}
</script>

<style lang="less">
.official-contract {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0D255D;
    border-radius: 30px;
    position: relative;
    margin-top: 100px;

    &::before {
        content: '';
        position: absolute;
        background-image: url(../images/official-contract-left.webp);
        background-position: center;
        background-size: cover;
        width: 520px;
        height: 207px;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 30px;
    }

    &::after {
        content: '';
        position: absolute;
        background-image: url(../images/official-contract-right.webp);
        background-position: center;
        background-size: cover;
        width: 520px;
        height: 190px;
        top: 0;
        right: 0;
        border-top-right-radius: 30px;
    }

    > .title {
        margin-top: 75px;
        width: 783px;
        font-weight: 800;
        font-size: 46px;
        line-height: 56px;
        background: linear-gradient(264.68deg, #FF45C8 -10.87%, #74BCFF 92.2%), var(--white);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }

    > .description {
        width: 950px;
        margin-top: 30px;
        font-size: 20px;
        line-height: 32px;
    }

    > .image {
        width: 900px;
        margin-bottom: 90px;
        margin-top: 40px;
        object-fit: cover;

        img {
            width: 100%
        }
    }
}

@media (max-width: 1279px) {
    .official-contract {
        margin-top: 70px;

        &::before {
            width: 372px;
            height: 136px;
        }

        &::after {
            width: 372px;
            height: 148px;
        }

        .title {
            width: 715px;
            font-size: 42px;
            line-height: 54px;
            margin-top: 60px;
        }

        .description {
            margin-top: 12px;
            width: 725px;
            font-size: 18px;
            line-height: 30px;
        }

        .image {
            width: 790px;
            margin-bottom: 70px;
            margin-top: 20px;
        }
    }
}

@media (max-width: 1023px) {
    .official-contract {
        margin-top: 60px;

        &::before {
            width: 294px;
            height: 107px;
        }

        &::after {
            width: 294px;
            height: 117px;
        }

        .title {
            width: 578px;
            font-size: 34px;
            line-height: 44px;
            margin-top: 50px;
        }

        .description {
            margin-top: 16px;
            width: 578px;
            font-size: 16px;
            line-height: 28px;
        }

        .image {
            width: 623px;
            margin-bottom: 70px;
            margin-top: 25px;
        }
    }
}

@media (max-width: 767px) {
    .official-contract {
        margin-top: 0px;
        padding: 0 15px 0;

        &::before {
            width: 294px;
            height: 107px;
        }

        &::after {
            width: 294px;
            height: 117px;
        }

        .title {
            width: 100%;
            font-size: 22px;
            line-height: 30px;
            margin-top: 36px;
            padding: 0 15px;
        }

        .description {
            margin-top: 12px;
            width: 100%;
            font-size: 14px;
            line-height: 24px;
            padding: 0 15px;
        }

        .image {
            width: 100%;
            margin-bottom: 43px;
        }
    }
}
</style>